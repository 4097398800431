import React, { Component } from 'react';
import { render } from 'react-dom';
import Highcharts from 'highcharts';
import ReactHighcharts from 'react-highcharts';

import './style.css';

var markerRadius = 5;

class App extends Component {
  componentDidMount() {
    let chart = this.refs.chart.getChart();
    //chart.series[0].addPoint({x: 10, y: 12});

    // set up the updating of the chart each second
    var series = chart.series[0];

    console.log(series);

    setInterval(function () {
      var x = (new Date()).getTime(), // current time
        y = Math.random();

        // console.log('top y: ' + y);
        // console.log(markerRadius);

      // series.addPoint([x, y], true, true);
      series.addPoint({
          x: x,
          y: y,
          dataLabels: {
            enabled: (function() {
              if ( y > 0.75 ) {
                return true;
              } else if ( y < 0.2 ) {
                return true;
              }
            }()),
            format: (function() {
              if ( y > 0.75 ) {
                return 'Sell';
              } else if ( y < 0.2 ) {
                return 'Buy Now';
              }
            }()),
          },
          name: "Point xhwha",
          marker: {
            // fillColor: y > 0.75 ? "green" : "",
            fillColor: (function() {
              if ( y > 0.75 ) {
                return "red";
              } else if ( y < 0.2 ) {
                return "green";
              }
            }()),
            // radius: y > 0.75 ? 15 : 5
            radius: (function() {
              if ( y > 0.75 ) {
                return 15;
              } else if ( y < 0.2 ) {
                return 15;
              }
            }()),
          }
      }, true, true);
      // console.log(series.data);
    }, 1000);

  }

  static formatTooltip(tooltip, x = this.x, y = this.y, series = this.series) {
    console.log(series.name);
    return `<b>${x}</b><br/>${series.name}: ${y}`;
  }

  static getConfig = (navs) => ({
    chart: {
      type: 'spline',
      animation: Highcharts.svg, // don't animate in old IE
      marginRight: 10,
    },
    title: {
      text: 'Stock XYZ'
    },
    xAxis: {
      type: 'datetime',
      tickPixelInterval: 150
    },
    yAxis: {
      title: {
        text: 'Value'
      },
      plotLines: [{
        value: 0,
        width: 1,
        color: '#808080'
      }]
    },
    tooltip: {
      formatter: function () {
        return '<b>' + this.series.name + '</b><br/>' +
          Highcharts.dateFormat('%Y-%m-%d %H:%M:%S', this.x) + '<br/>' +
          Highcharts.numberFormat(this.y, 2);
      }
    },
    legend: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    series: [{
      name: 'Random data',
      marker: {
        radius: markerRadius
      },
      data: (function () {
        // generate an array of random data
        var data = [],
          time = (new Date()).getTime(),
          i,
          yPoint;

        for (i = -19; i <= 0; i += 1) {
          data.push({
            x: time + i * 1000,
            y: (function() {
              yPoint = Math.random();
              return yPoint;
            }()),
            // marker: {
            //   fillColor: yPoint > 0.75 ? "green" : "",
            //   radius: yPoint > 0.75 ? 15 : 5
            // }

            // (function() {
            //       console.log(yPoint);

            //       if ( yPoint > 0.5 ) {
            //         return '{fillColor: "yellow"}'
            //       } else {
            //         return '{fillColor: "red"}'
            //       }
            //     }())
            // {
            //     fillColor: el[1] <= 99.9 ? "yellow" : "blue"
            // }
          });
        }
        return data;
      }())
    }]
  })

  render() {
    return (
      <div>
        <ReactHighcharts config={App.getConfig(this.props.navs)} ref="chart" />
      </div>
    );
  }
}

export default App;
